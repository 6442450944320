// src/components/CV.js
import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const CV = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 4,
        backgroundColor: theme.palette.background.default,
        padding: 3,
        borderRadius: 2,
        boxShadow: `0px 0px 10px ${theme.palette.primary.transparent}`,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: theme.palette.primary.text }}
      >
        My CV for development
      </Typography>
      <img
        src="/cv-image.jpg"
        alt="My CV"
        style={{
          maxWidth: "100%",
          height: "auto",
          border: `2px solid ${theme.palette.primary.border}`,
        }}
      />
    </Box>
  );
};

export default CV;
